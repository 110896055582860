import styled, {css} from 'styled-components';

const paddings = css`
  padding-left: 1rem;
  padding-right: 1rem;
`;

export const Title1 = styled.h1`
  font-size: 1.875rem;
  line-height: 3rem;
  font-weight: 500;
  text-align: left;
  margin-bottom: 4.375rem;
  ${paddings};
  text-transform: uppercase;
`;

export const Paragraph = styled.p`
  font-size: 1rem;
  line-height: 2.25rem;

  ${({noBtmMargin}) => noBtmMargin && `
    margin-bottom: 0;
  `
  }

  @media (min-width: 576px) {
    font-size: 1.125rem;
    line-height: 3rem;
  }
`;