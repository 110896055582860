import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import SEO from '../components/seo';
import { ContentWrapper } from '../components/common';
import { Title1 } from '../components/headings';
import { HOME } from '../routes';
import { Link } from 'gatsby';
import theme from '../theme';

const PrimaryBtn = styled(Link)`
   display: inline-block;
  padding: 11.5px 20px !important;
  border-radius: 30px;
  cursor: pointer;
  outline: none;
  font-size: 16px;
    letter-spacing: 0.5px;
    transition: all 0.3s;
    font-weight: 600;
    background-color: #f59401;
    border: 1px solid #f59401;
    color: #fff !important;
    text-align: center;
    box-shadow: 0 3px 7px rgba(98,98,98, 0.5);
    margin-top: 2rem;
    text-decoration: none;

    &:hover {
      background-color: #e78f0b;
      border: 1px solid #e78f0b;
      color: #fff !important;
      text-decoration: none;

    }
`;

const Wrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;

  img {
    max-width: 250px;
    margin-bottom: 2rem;
  }
`;

const Container = styled.div`
  padding: 7rem 0;
  text-align: center;

  main {
    border: 1px solid red;
  }

  ${Title1} {
    font-size: 5rem;
    line-height: 6.25rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    margin-bottom: 0;
    text-align: center;
    /* color: #515151; */
  }

  p {
    font-size: 1.375rem;
    line-height: 3.75rem;
    margin-bottom: 2.5rem;
  }
`;

const BtnsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const NotFoundPage = () => (
  <ThemeProvider theme={theme}>
    <Wrapper>
      <SEO title="Stránka nebyla nalezena" />
      <Container>
        <ContentWrapper>
          <Title1>404</Title1>
          <p>Stránka nebyla nalezena</p>
          <BtnsWrapper>
            <PrimaryBtn to={HOME}>Přejít na hlavní stránku</PrimaryBtn>
          </BtnsWrapper>
        </ContentWrapper>
      </Container>
    </Wrapper>
  </ThemeProvider>
)

export default NotFoundPage;
